





























































































































































import { ErrorManager } from '@/models/error';
import { FormRules } from '@/utils/formRules';
import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  name: 'ForgetPassword',
  mixins: [FormRules],
  data() {
    return {
      show: false,
      dialog: false,
      email: '',
      errorMessage: '',
      valid: false,
      loading: false,
    };
  },

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },

  methods: {
    ...mapActions({
      forgetPassword: 'auth/forgetPassword',
    }),

    goToSignUpPage() {
      this.$router.push({ name: 'sign-up' });
    },
    goToLogin() {
      this.$router.push({ name: 'login' });
    },
    sendEmail() {
      this.loading = true;
      this.forgetPassword(this.email)
        .then((data) => {
          this.dialog = true;
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.show = true;
  },
});
